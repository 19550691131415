<template>
    <div class="sList">
        <div 
          v-for="(i,k) in list"
          :key="k"
          class="list_item"
          @click="handlerClick(i)"
        >
            <div style="display: flex;">
              <div v-if="i.status === '1'" class="dot_online"></div>
              <div v-else class="dot_offline"></div>
              <div style="margin-left: 5px;" class="single-line">
                  {{ i.deviceName }}
              </div>
            </div>
            <div style="font-size: 12px; margin-top: 10px; color: #666;">
               {{ i.deviceType }} 
            </div>
            <el-tooltip
                placement="right"
                effect="dark"
                :content="i.address"
            >
                <div class="single-line" style="font-size: 12px; margin-top: 10px; color: #666;">
                    <span>安装地址:</span>
                    <span>{{ i.address }}</span>
                </div>
            </el-tooltip>
           
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },
    },
    mounted() {
        this.list = this.list.sort((a,b) => {
            return b.status - a.status
        })
    },
    methods: {
        handlerClick(item) {
            this.$parent.$parent.$parent.getLatestData(item)
            this.$parent.$parent.$parent.drawerdevice = item.device
            this.$parent.$parent.$parent.center = [item.positionLng,item.positionLat]
        }
    }
}
</script>

<style lang="less" scoped>
.sList { 
    height: 800px;
    overflow: auto;
    .list_item {
        z-index: 100;
        width: 217px;
        height: 90px;
        background: #FFFFFF;
        border-radius: 12px 5px 12px 12px;
        margin: 0 auto;
        margin-top: 10px;   
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #0094FF;
        cursor: pointer;
    .dot_online {
        width: 9px;
        height: 9px;
        background: #2CAE51;
        border-radius: 50%;
    }
    .dot_offline {
        width: 9px;
        height: 9px;
        background: #D7DED9;
        border-radius: 50%;
        margin-top: 3px;
    }
    }
}
.single-line {
    white-space: nowrap;      /* 保持文本在一行，不换行 */
    overflow: hidden;         /* 超出容器部分隐藏 */
    text-overflow: ellipsis;  /* 超出部分显示为省略号 */
}
</style>