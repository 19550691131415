export function windDegreeToDir(wd) {
  if (wd > 22.5 && wd <= 67.5) {
    return '东北风'; //东北
  } else if (wd > 67.5 && wd <= 112.5) {
    return '东风'; //东
  } else if (wd > 112.5 && wd <= 157.5) {
    return '东南风'; //东南
  } else if (wd > 157.5 && wd <= 202.5) {
    return '南风'; //南
  } else if (wd > 202.5 && wd <= 247.5) {
    return '西南风'; //西南
  } else if (wd > 247.5 && wd <= 292.5) {
    return '西风'; //西
  } else if (wd > 292.5 && wd <= 337.5) {
    return '西北风'; //西北
  } else if (wd && (wd > 337.5 || wd <= 22.5)) {
    return '北风'; //北
  } else {
    return '无风向'; //无风向
  }
}

export function windSpeedToLevel(speed) {
  let spd = parseFloat(speed);
  let level;
  if (spd < 0.3) {
    level = 0;
  } else if (spd < 1.6) {
    level = 1;
  } else if (spd < 3.4) {
    level = 2;
  } else if (spd < 5.5) {
    level = 3;
  } else if (spd < 8.0) {
    level = 4;
  } else if (spd < 10.8) {
    level = 5;
  } else if (spd < 13.9) {
    level = 6;
  } else if (spd < 17.2) {
    level = 7;
  } else if (spd < 20.8) {
    level = 9;
  } else if (spd < 24.5) {
    level = 10;
  } else if (spd < 28.5) {
    level = 11;
  } else if (spd < 32.7) {
    level = 12;
  } else {
    level = 13;
  }
  return level;
}

export function UVToDir(u, v) {
  let fx;
  if (u > 0 & v > 0) {
    fx = 270 - Math.atan(v / u) * 180 / Math.PI;
  }
  else if (u < 0 & v > 0) {
    fx = 90 - Math.atan(v / u) * 180 / Math.PI;
  }
  else if (u < 0 & v < 0) {
    fx = 90 - Math.atan(v / u) * 180 / Math.PI;
  }
  else if (u > 0 & v < 0) {
    fx = 270 - Math.atan(v / u) * 180 / Math.PI;
  }
  else if (u == 0 & v > 0) {
    fx = 180;
  }
  else if (u == 0 & v < 0) {
    fx = 0;
  }
  else if (u > 0 & v == 0) {
    fx = 270;
  }
  else if (u < 0 & v == 0) {
    fx = 90;
  }
  else if (u == 0 & v == 0) {
    fx = 999.9;
  }
  return fx;
}

export function UVToSpeed(u, v) {
  return Math.sqrt(Math.pow(u, 2) + Math.pow(v, 2)).toFixed(2);
}