// 温度
const tem = [
    {
        color:'#83007f',
        scope:''
    },
    {
        color:'#002D8E',
        scope:'-32'
    },
    {
        color:'#185BAE',
        scope:'-28'
    },
    {
        color:'#2272D1',
        scope:'-24'
    },
    {
        color:'#35A2F4',
        scope:'-20'
    },
    {
        color:'#75D1F6',
        scope:'-16'
    },
    {
        color:'#97E1F1',
        scope:'-12'
    },
    {
        color:'#BFF7FF',
        scope:'-8'
    },
    {
        color:'#F3FCFC',
        scope:'-4'
    },
    {
        color:'#DCFEDA',
        scope:'0'
    },
    {
        color:'#C2FFB7',
        scope:'4'
    },
    {
        color:'#BBFE7D',
        scope:'8'
    },
    {
        color:'#FDFCA0',
        scope:'12'
    },
    {
        color:'#FFF3BF',
        scope:'16'
    },
    {
        color:'#FEDD9F',
        scope:'20'
    },
    {
        color:'#FFAC6F',
        scope:'24'
    },
    {
        color:'#FC8784',
        scope:'28'
    },
    {
        color:'#E85D5F',
        scope:'32'
    },
]
// 湿度
const rhu = [
    {
        color: '#d1fdca',
        scope: '0'
    },
    {
        color: '#a9fa9e',
        scope: '10'
    },
    {
        color: '#89ec80',
        scope: '20'
    },
    {
        color: '#6bdc67',
        scope: '30'
    },
    {
        color: '#50d34d',
        scope: '40'
    },
    {
        color: '#3acc38',
        scope: '50'
    },
    {
        color: '#2cc22b',
        scope: '60'
    },
    {
        color: '#26b426',
        scope: '70'
    },
    {
        color: '#22a8aa',
        scope: '80'
    },
    {
        color: '#1ea420',
        scope: '90'
    }
]
// 降雨
const pre = [
    {
        color: '#91f18e',
        scope: '0'
    },
    {
        color: '#12a42e',
        scope: '2'
    },
    {
        color: '#57b6f9',
        scope: '4'
    },
    {
        color: '#2429eb',
        scope: '6'
    },
    {
        color: '#005f43',
        scope: '8'
    },
    {
        color: '#f600ee',
        scope: '10'
    },
    {
        color: '#b9325b',
        scope: '20'
    },
    {
        color: '#f22e1c',
        scope: '50'
    },
    {
        color: '#6d000a',
        scope: '≥100mm'
    },

]
// 气压
const prs = [
    {
        color: '#7a096b',
        scope: '746hPa'
    },
    {
        color: '#751490',
        scope: '776hPa'
    },
    {
        color: '#45368b',
        scope: '806hPa'
    },
    {
        color: '#466a77',
        scope: '836hPa'
    },
    {
        color: '#479c73',
        scope: '866hPa'
    },
    {
        color: '#7cb053',
        scope: '896hPa'
    },
    {
        color: '#b7c939',
        scope: '926hPa'
    },
    {
        color: '#c29d46',
        scope: '956hPa'
    },
    {
        color: '#cd7633',
        scope: '986hPa'
    },
    {
        color: '#ba5844',
        scope: '1016hPa'
    },
    {
        color: '#b9325b',
        scope: '1046hPa'
    },
]
// 风速
const wind = [
    {
        color: '#bbfb98',
        scope: '0'
    },
    {
        color: '#5df708',
        scope: '2'
    },
    {
        color: '#d2fd09',
        scope: '4'
    },
    {
        color: '#fff90c',
        scope: '6'
    },
    {
        color: '#ffc601',
        scope: '8'
    },
    {
        color: '#f8870a',
        scope: '10'
    },
    {
        color: '#fb4805',
        scope: '12'
    },
    {
        color: '#fa0203',
        scope: '14'
    },
    {
        color: '#c55a01',
        scope: '16'
    },
    {
        color: '#7a0301',
        scope: '18'
    },
    {
        color: '#712b00',
        scope: '20'
    },
]

// 光照
const sun = [
    {
        color: '#666',
        scope: '0'
    },
    {
        color: '#71a0cd',
        scope: '200'
    },
    {
        color: '#1f68b5',
        scope: '400'
    },
    {
        color: '#017596',
        scope: '600'
    },
    {
        color: '#00b469',
        scope: '800'
    },
    {
        color: '#6fb031',
        scope: '1000'
    },
    {
        color: '#b7c939',
        scope: '1200'
    },
    {
        color: '#c29d46',
        scope: '1400'
    },
    {
        color: '#ba5844',
        scope: '1600'
    },
    {
        color: '#f72e00',
        scope: '≥1800'
    },
]
export {
    tem,rhu,pre,prs,wind,sun
}